import { AboutUs } from "@/components/AboutUs";
import SEO from "@/components/SEO";
import { graphql, PageProps, StaticQuery } from "gatsby";
import React, { FC, Fragment } from "react";

const AboutUsPahe: FC<PageProps> = () => (
  <StaticQuery
    query={graphql`
      query AboutUSPage {
        #   SEO
        contentfulAboutUsPage {
          seo {
            documentTitle
            metaTitle
            metaUrl
            metaAuthor
            description {
              description
            }
            metaKeywords
          }

          #     WHo we are
          whoWeAre {
            content {
              title
              description {
                description
              }
              quote {
                quote
              }
              image {
                file {
                  url
                }
              }
              alignment
            }
          }

          workWithUs {
            joinUsPostsBox {
              id
              title
              subTitle
            }
          }

          #     Footer Content
          footerContent {
            content {
              raw
            }
          }
        }
      }
    `}
    render={({
      contentfulAboutUsPage: {
        seo: {
          documentTitle,
          metaTitle,
          metaUrl,
          description: { description },
          metaKeywords,
          metaAuthor,
        },
        footerContent,
        whoWeAre,
        workWithUs,
      },
    }) => {
      return (
        <Fragment>
          <SEO
            contentfulSeo={{
              documentTitle,
              metaTitle,
              metaDescription: description,
              metaKeywords,
              metaUrl,
              metaAuthor: metaAuthor,
            }}
          />
          <AboutUs footerContent={footerContent} whoWeAre={whoWeAre} workWithUs={workWithUs} />
        </Fragment>
      );
    }}
  />
);

export default AboutUsPahe;
