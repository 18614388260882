import { WhoWeAreProps } from "@/components/AboutUs/WhoWeAre";
import loadable from "@loadable/component";
import React, { Component } from "react";
import Layout from "../Layout";
import { BreadCrumb } from "../Shared/BreadCrumb";

const WhoWeAre = loadable(() => import("@/components/AboutUs/WhoWeAre").then((d) => d.default));
const JoinUs = loadable(() => import("@/components/AboutUs/JoinUs").then((d) => d.JoinUs));

interface AboutUSPageProps extends WhoWeAreProps {
  footerContent: any;
  workWithUs: any;
}

export class AboutUs extends Component<AboutUSPageProps> {
  render(): JSX.Element {
    const { footerContent, whoWeAre, workWithUs } = this.props;
    return (
      <Layout contentfulFooterContentForPages={footerContent}>
        <BreadCrumb
          routingPaths={[{ path: "/", routeName: "Home" }]}
          activePath={{ routeName: "About Us" }}
          title="About Us"
        />
        <WhoWeAre whoWeAre={whoWeAre} />
        <JoinUs workWithUs={workWithUs} />
      </Layout>
    );
  }
}
